import './css/App.css';
import KeyPiano from './KeyPiano';
import ChordPiano from './ChordPiano';

function Music() {
  return (
    <>
    <div id="headerSpacer" />
      <div id="wrapper">
        <KeyPiano />
        <ChordPiano />
      </div>
    </>
  );
}

export default Music;
