/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { NoteType } from '../helpers';
import Note from './Note';
import '../css/App.css';

const NOTE_WIDTH = 30; // Example: 50 pixels
const NOTES_PER_OCTAVE = 12; // Number of notes per octave

type Props = {
  setPianoOctaves: (value: any) => void;
  notes: NoteType[];
  pianoType: string;
  noteInformation: { id: string; isPrimary: boolean; degree: string; actualValue: string }[]; // Add this line
};

const PianoComponent: React.FC<Props> = ({ notes, pianoType, setPianoOctaves, noteInformation: noteInformation }) => {  const [visibleNoteCount, setVisibleNoteCount] = useState<number>(0);
  
  useEffect(() => {
    const handleResize = () => {
      // Calculate how many notes can fit on the screen
      let width = 400;

      if(window.innerWidth > 400){
        width = window.innerWidth;
      }

      const numberOfVisibleNotes = Math.floor(width / NOTE_WIDTH);
      setVisibleNoteCount(numberOfVisibleNotes);
      setPianoOctaves(numberOfVisibleNotes / 12);
    };

    // Call handleResize on component mount and on window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [noteInformation]); // Dependency array, re-run effect if notes array changes

const getHighlightedClass = (noteId: string) => {
  if(!noteInformation){
    return; 
  }
  const note = noteInformation.find(n => n.id === `${noteId} ${pianoType}`);
  if (!note) {
    return '';
  }

  switch (pianoType) {
    case 'chord':
      return note.isPrimary ? 'chordPrimaryHighlighted' : 'chordHighlighted';
    case 'key':
      return 'keyHighlighted';
    default:
      return '';
  }
};

const getNoteDegree = (noteId: string) => {
  const note = noteInformation.find(n => n.id === `${noteId} ${pianoType}`);
  if (!note) {
    return '';
  }
  return note.degree;
};

const getNoteActualValue = (noteId: string) => {
  const note = noteInformation.find(n => n.id === `${noteId} ${pianoType}`);
  if (!note) {
    return '';
  }
  return note.actualValue;
};

const renderOctaves = () => {
  const numberOfOctaves = Math.floor(visibleNoteCount / NOTES_PER_OCTAVE);
  let renderedOctaves = [];

  for (let octave = 0; octave < numberOfOctaves; octave++) {
      renderedOctaves.push(
          notes.map((element: NoteType) => (
            <Note
              actualValue={getNoteActualValue(`${element.note}${octave + 1}`)}
              degree={getNoteDegree(`${element.note}${octave + 1}`)}
              key={`${element.note}`}
              colour={element.colour}
              note={`${element.note}${octave + 1}`}
              pianoType={pianoType}
              highlightedClass={getHighlightedClass(`${element.note}${octave + 1}`)}
            />
          ))
      );
  }
  return renderedOctaves;
};

  return ( 
    <div id="piano">
      <div>
        {renderOctaves()}
      </div>
    </div>
  );
};

export default PianoComponent;
