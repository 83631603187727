import { useEffect, useState } from 'react';
import './css/App.css';
import { User, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebaseConfig';
import Header from './components/Header';
import { Route, Routes } from 'react-router-dom';
import PremiumPage from './components/Premium';
import ContactPage from './components/Contact';
import PrivacyPage from './components/Privacy';
import HomePage from './components/Home';

function App() {
    const [currentUser, setCurrentUser] = useState<User | null>(null);
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in
          setCurrentUser(user);
        } else {
          // User is signed out
          setCurrentUser(null);
        }
      });
      return () => unsubscribe();
    }, []);
  
    return (
      <div id="RootApplicationContainer">
        <Header user={currentUser} />
        <Routes>
          <Route path="/" element={<HomePage user={currentUser} />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/premium" element={<PremiumPage />} />
        </Routes>
      </div>
    );
  }

export default App;