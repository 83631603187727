import './css/App.css';
import { useEffect, useState } from 'react';
import { noteList, notes, EnharmonicEquivalents, ConvertToRomanNumerals, AreNoteBasesAdjacent } from './helpers';
import Piano from './components/Piano';


interface KeyPianoState {
  lastNote: string;
  currentKey: string;
  noteInformation: { id: string; isPrimary: boolean; degree: string; actualValue: string }[];
}

interface VisibilityState {
  keySelector: 'visible' | 'hidden';
  lastNote: 'visible' | 'hidden';
  currentKey: 'visible' | 'hidden';
}

function CalculateNoteInformation(currentKey: String, startingNote: string, pianoOctaves: number) {

  const noteList = [
    'C', 'C#', 'D', 'D#', 'E',
    'F', 'F#', 'G', 'G#', 'A',
    'A#', 'B',
  ];

  const startingNoteIndex = noteList.indexOf(startingNote);
  const reorderedNoteList = noteList.splice(startingNoteIndex).concat(noteList);

  let pattern: string = '';
  let minorDegreeLocations: number[] = []
  switch (currentKey) {
    case 'Major':
      pattern = 'PSPSPPSPSPSP';
      minorDegreeLocations = [2, 3, 6, 7];
      break;
    case 'Minor':
      pattern = 'PSPPSPSPPSPS';
      minorDegreeLocations = [1, 4, 7];
      break;
    case 'Harmonic Major':
      pattern = 'PSPSPPSPPSSP';
      break;
    case 'Harmonic Minor':
      pattern = 'PSPPSPSPPSSP';
      break;
    case 'Blues Major':
      pattern = 'PSPPPSSPSPSS';
      break;
    case 'Blues Minor':
      pattern = 'PSSPSPPPSSPS';
  }

  const noteInformation: { id: string; isPrimary: boolean; degree: string; actualValue: string }[] = [];
  let skippedCount = 0;
  for (let i = 0; i < 12; i += 1) {
    for (let j = 1; j <= 1; j++) {
      if (pattern.charAt(i) === 'P') {
        const degree = (i + 1) - skippedCount;
        let romanNumeral = ConvertToRomanNumerals(degree);
        if (minorDegreeLocations.includes(degree)) {
          romanNumeral = romanNumeral.toLowerCase();
        } else if (minorDegreeLocations.length == 0) {
          romanNumeral = '';
        }
        noteInformation.push({ id: `${reorderedNoteList[i]} key`, isPrimary: false, degree: `${romanNumeral}`, actualValue: `${reorderedNoteList[i]}` });
      } else {
        skippedCount++;
        break;
      }
    }
  }
  // After populating noteInformation array
  for (let noteIndex = 1; noteIndex <= noteInformation.length; noteIndex++) {
    const currentNoteBase = noteInformation[noteIndex % noteInformation.length].actualValue[0] || noteInformation[noteIndex].id[0]; // Get the base letter of the current note

    const previousNoteBase = noteInformation[noteIndex - 1].actualValue[0]; // Get the base letter of the previous note

    const areBasesTheSame = currentNoteBase === previousNoteBase;
    const areBasesAdjacent = AreNoteBasesAdjacent(previousNoteBase, currentNoteBase);

    let targetNoteIndex = null;

    if (areBasesTheSame) {
      targetNoteIndex = noteIndex - 1;
    }

    if (!areBasesAdjacent) {
      targetNoteIndex = noteIndex % noteInformation.length;
    }

    if (targetNoteIndex) {
      const fullNoteName = (noteInformation[targetNoteIndex].id.slice(0, -4)).replace(/\d+/g, ''); // Assuming ' key' suffix is 4 characters long

      if (fullNoteName in EnharmonicEquivalents) {
        const enharmonicEquivalent = EnharmonicEquivalents[fullNoteName as keyof typeof EnharmonicEquivalents]; // Use type assertion
        if (enharmonicEquivalent) {
          noteInformation[targetNoteIndex].actualValue = `${enharmonicEquivalent}`; // Re-append the ' key' suffix
        }
      }
    }
  }

  const duplicatedNoteInformation: { id: string; isPrimary: boolean; degree: string; actualValue: string }[] = [];
  for (let octave = 1; octave <= pianoOctaves; octave++) {
    noteInformation.forEach(note => {
      duplicatedNoteInformation.push({
        ...note,
        id: `${note.id.slice(0, -4)}${octave} key` // Remove ' key' and append octave number
      });
    });
  }
  
  return duplicatedNoteInformation;
}

const KeyPiano: React.FC<{}> = () => {
  const [pianoOctaves, setPianoOctaves] = useState<number>(0);
  const [pianoState, setPianoState] = useState<KeyPianoState>({
    lastNote: '',
    currentKey: '',
    noteInformation: []
  });

  const [visibility, setVisibility] = useState<VisibilityState>({
    keySelector: 'hidden',
    lastNote: 'hidden',
    currentKey: 'hidden'
  });

  useEffect(() => {
    for (let i = 0; i < noteList.length; i += 1) {
      for (let octave = 1; octave < pianoOctaves; octave += 1) {
        
        const element = (document.getElementById(`${noteList[i]}${octave} key`) as HTMLInputElement);
        const selector = (document.getElementById('keySelector') as HTMLInputElement);

        const handleNoteSelection = function (this: any) {
          let elementID: string = element.id.split(' ')[0].slice(0, -1);

          setVisibility({
            keySelector: 'visible',
            lastNote: 'visible',
            currentKey: 'visible'
          });

          setPianoState({
            lastNote: element.id.slice(0, -4),
            currentKey: `${noteList[i]} ${selector.value}`,
            noteInformation: CalculateNoteInformation(selector.value, elementID, pianoOctaves!)
          });

        };

        if (element) {
          element.addEventListener('mousedown', handleNoteSelection, true);
          element.addEventListener('touchstart', handleNoteSelection, true);
        }
      }
    }

  }, [pianoOctaves]);

  return (
    <>
      <select name="keySelector" id="keySelector" style={{ visibility: visibility.keySelector }}>
        <option value="Major">Natural Major</option>
        <option value="Minor">Natural Minor</option>
        <option value="Harmonic Major">Harmonic Major</option>
        <option value="Harmonic Minor">Harmonic Minor</option>
        <option value="Blues Major">Blues Major</option>
        <option value="Blues Minor">Blues Minor</option>
      </select>
      <div id="currentKey" style={{ visibility: visibility.currentKey }}>
        {`Key: ${pianoState.currentKey}`}
      </div>
      <div id="lastNote" style={{ visibility: visibility.lastNote }}>
        {`Last Note: ${pianoState.lastNote}`}
      </div>
      <br />
      <div id="keyPianoWrapper">
        <Piano notes={notes} pianoType="key" noteInformation={pianoState.noteInformation} setPianoOctaves={setPianoOctaves} />
      </div>
    </>
  );
}

export default KeyPiano;


