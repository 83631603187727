import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig'; // Ensure you have this Firebase config file
import '../css/Contact.css'

const ContactPage = () => {
  const [suggestion, setSuggestion] = useState('');

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      await addDoc(collection(db, 'userSuggestions'), {
        content: suggestion,
        timestamp: new Date(),
      });
      alert('Thank you for your suggestion!');
      setSuggestion(''); // Clear the input after submission
    } catch (error) {
      console.error('Error adding suggestion: ', error);
      alert('Failed to send suggestion. Please try again later.');
    }
  };

  return (
    <div className="Contact-Container">
      <h1>Contact Us</h1>
      <form onSubmit={handleSubmit}>
        <textarea
          id="suggestion"
          value={suggestion}
          onChange={(e) => setSuggestion(e.target.value)}
          placeholder="Type your anonymous suggestion here, please include contact information if necessary..."
          required
        />
        <button className="submitSuggestionButton" type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactPage;
