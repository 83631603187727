import React from 'react';
import '../css/Privacy.css'
import { Link } from 'react-router-dom';


const PrivacyPage = () => {
    return (
        <div className="privacy-container">
            <h1>Privacy Policy</h1>
            <p>
                Only authentication and account management data is stored/collected in our database. We respect your right to privacy and ensure that all personal information is kept confidential.
                <br />
                <strong>No passwords or payment information will ever be stored/collected, third party providers ensure that our team cannot view or access any sensitive user information.</strong>
            </p>

            <p>
                Our application is designed for both commercial and non-commercial purposes. You are free to use our app in any context that suits your needs.
            </p>
            <p>
                If you have any feedback or suggestions, please share your thoughts via the {<Link to="/contact">Contact Us </Link>}
                page. We value your input and are always looking for ways to improve our application.
                <br />
                <strong>All suggestions are anonymous, for account queries please include contact information in your suggestion.</strong>

            </p>
        </div>
    );
};

export default PrivacyPage;
