import React from 'react';
import { auth, provider } from '../firebaseConfig'; // Adjust the path as needed
import { signOut, signInWithPopup } from 'firebase/auth';
import '../css/Header.css';
import '../css/Login.css'
import { User } from 'firebase/auth';
import { Link } from 'react-router-dom';

type HeaderProps = {
  user: User | null;
};

const Header: React.FC<HeaderProps> = ({ user }) => {

  const handleLoginWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error("Error signing in with Google: ", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // Handle sign-out logic (like redirecting or updating state)
    } catch (error) {
      console.error("Error signing out: ", error);
      // Handle sign-out error
    }
  };

  return (
    <header className="Header-container">
      <nav>
        <Link className="headerNavigationButton" to="/">Home</Link>
        <Link className="headerNavigationButton" to="/contact">Contact Us</Link>
        <Link className="headerNavigationButton" to="/privacy">Privacy</Link>
        <Link className="headerNavigationButton" to="/premium">Premium</Link>
      </nav>
      <div>
        {user ? (
          <>
            <button className='signOutButton' onClick={handleSignOut}>Sign Out</button>
          </>
        ) : (
          <button className='signInWithGoogleButton' onClick={handleLoginWithGoogle}>Sign in with Google</button>
        )}
      </div>
    </header>
  );
}

export default Header;