import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig'; // Ensure you have this Firebase config file
import '../css/Contact.css'
import MusicScale from '../MusicScale'
import { User } from 'firebase/auth';

type HomeProps = {
    user: User | null;
};

const HomePage: React.FC<HomeProps> = ({ user }) => {
  return (
    <div>
        {true ? (
            <>
            <MusicScale />
            </>
        ) : (
            <p>Please sign in to view the homepage.</p>
        )}
    </div>  
  );
};

export default HomePage;
