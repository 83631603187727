export const noteList = [
  'C', 'C#', 'D', 'D#', 'E',
  'F', 'F#', 'G', 'G#', 'A',
  'A#', 'B',
];

export type NoteType = {
    [key: string]: string;
}

export const notes: NoteType[] = [
  {
    note: 'C',
    colour: 'white',
  },
  {
    note: 'C#',
    colour: 'black',
  },
  {
    note: 'D',
    colour: 'white',
  },
  {
    note: 'D#',
    colour: 'black',
  },
  {
    note: 'E',
    colour: 'white',
  },
  {
    note: 'F',
    colour: 'white',
  },
  {
    note: 'F#',
    colour: 'black',
  },
  {
    note: 'G',
    colour: 'white',
  },
  {
    note: 'G#',
    colour: 'black',
  },
  {
    note: 'A',
    colour: 'white',
  },
  {
    note: 'A#',
    colour: 'black',
  },
  {
    note: 'B',
    colour: 'white',
  },
];

export type NoteName = 'C' | 'C#' | 'D' | 'D#' | 'E' | 'F' | 'F#' | 'G' | 'G#' | 'A' | 'A#' | 'B' | 'Db' | 'Eb' | 'Gb' | 'Ab' | 'Bb' | 'E#' | 'B#' | 'Fb' | 'Cb' | 'F*' | 'C*' | 'G*';

export const EnharmonicEquivalents: { [K in NoteName]?: NoteName } = {
  'C#': 'Db',
  'D#': 'Eb',
  'F#': 'Gb',
  'G#': 'Ab',
  'A#': 'Bb',
  'Db': 'C#',
  'Eb': 'D#',
  'Gb': 'F#',
  'Ab': 'G#',
  'Bb': 'A#',
  // Special cases for theoretical enharmonic equivalents
  'E#': 'F',
  'B#': 'C',
  'Fb': 'E',
  'Cb': 'B',
  'F': 'E#',
  'C': 'B#',
  'E': 'Fb',
  'B': 'Cb',

  'F*': 'G',
  'G': 'F*',

  'C*': 'D',
  'D': 'C*',

  'G*': 'A',
  'A': 'G*',

};

export function ConvertToRomanNumerals(inputNumber: number) {
  let number = inputNumber;
  let roman = '';
  const decimal = [5, 4, 1];
  const romanNum = ['V', 'IV', 'I'];
  for (let i = 0; i < decimal.length; i += 1) {
    while (decimal[i] <= number) {
      roman += romanNum[i];
      number -= decimal[i];
    }
  }
  return roman;
}

export function AreNoteBasesAdjacent(previous: string, current: string) {
  const notes = 'ABCDEFG';
  const prevIndex = notes.indexOf(previous);
  const currentIndex = notes.indexOf(current);
  // Check for adjacent notes including wrap-around from G to A
  return Math.abs(prevIndex - currentIndex) === 1 || Math.abs(prevIndex - currentIndex) === 6;
}

export function CalculateInversionSuffix(i: number) {
  const j = i % 10,
        k = i % 100;
  if (j === 1 && k !== 11) {
      return i + "st";
  }
  if (j === 2 && k !== 12) {
      return i + "nd";
  }
  if (j === 3 && k !== 13) {
      return i + "rd";
  }
  return i + "th";
}
